<template>
  <div class="orderDetail">
    <div class="orderDetail-header">
      <div class="header-info">
        <div class="header-title">让货物飞一会儿</div>
        <div class="header-subtitle">
          阁下的宝物正在交由镖局，<br>
          愿Ta以火箭般的速度飞到您手中！
        </div>
      </div>
      <img src="@/assets/images/logistics.png" class="header-img">
    </div>

    <div class="orderDetail-content">
      <div class="userInfo">
        <div class="userInfo-spotBox">
          <img src="@/assets/images/icon/spot.png" class="userInfo-spot">
        </div>

        <div class="userInfo-content">
          <div class="userInfo-info">
            <div class="userInfo-name">{{consignee}}</div>
            <div class="userInfo-phone">{{telephone}}</div>
          </div>

          <div class="userInfo-address">
            {{province}} {{city}} {{district}} {{street}}
          </div>
        </div>
      </div>

      <div v-for="(item, index) in gifts" :key="index" class="orderDetail-item">
        <div v-if="item.logisticsNumber" class="logistics">
          <div class="logistics-iconBox">
            <img src="@/assets/images/icon/truck.png" class="logistics-icon" />
          </div>
          <div class="logistics-content">
            <div class="logistics-row">
              <span>物流公司：</span>{{item.logisticsCompanyName}}
            </div>
            <div class="logistics-row">
              <span>物流单号：</span>{{item.logisticsNumber}}
            </div>
          </div>
          <div @click="showLogisticsModal(index)" class="logistics-btn">追踪物流</div>
        </div>
        <div v-else class="logistics noLogistics">
          <div class="logistics-iconBox">
            <img src="@/assets/images/icon/truck.png" class="logistics-icon"> 
          </div>
          <div class="logistics-content">
            <div class="logistics-row">
              <span>货物准备中</span>
            </div>
          </div>
        </div>

        <div class="product" v-for="(gift, j) in item.items" :key="(i+'_'+j)">
          <div class="product-imgBox">
            <img :src="gift.image" class="product-img" />
          </div>

          <div class="product-content">
            <div class="product-name">{{gift.giftName}}</div>
            <div class="product-params">{{gift.specString}} X{{gift.giftCount}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isShowLogisticsModal" class="logisticsModal">
    <div class="logisticsModal-content">
      <div class="logisticsModal-header">
        <div class="header-row">
          <span>物流公司：</span>{{logisticsCompanyName}}
        </div>
        <div class="header-row">
          <span>物流单号：</span>{{logisticsNumber}} 
        </div>
      </div>

      <div class="logisticsModal-main">
        <div class="logisticsModal-line"></div>
        <div class="logisticsModal-detail">
          <div
            v-for="(item, index) in logisticsInfo"
            :key="index" 
            class="logisticsModal-row">
            <div class="row-dotBox">
              <img src="@/assets/images/icon/red-dot.png" class="row-dot">
            </div>

            <div class="row-content">
              {{item.context}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div 
      @click="hideLogisticsModal" 
      class="btn btn-white-border logisticsModal-btn">我知道了</div>
  </div>
</template>

<script src='./OrderDetail.js'></script>

<style scoped lang="scss" src="./OrderDetail.scss"></style>