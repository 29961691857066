
import {
  reactive,
  toRefs,
  watch,
  inject,
} from "vue"
import { useRouter, useRoute } from 'vue-router'

import { 
  getBookingDetailApi,
  getLogisticsInforApi,
} from "@/api/order"
import { 
  formatPhone,
  defaultError,
} from '@/utils/common.js'

export default {
  name: 'OrderDetail',
  components: {
    
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const toast = inject('toast')
    const loading = inject('loading')

    const state = reactive({
      consignee: '',
      telephone: '',
      province: '',
      city: '',
      district: '',
      street: '',
      gifts: [],
      isShowLogisticsModal: false,
      logisticsCompanyName: '',
      logisticsNumber: '',
      logisticsInfo: [],
    })

    watch(
      () => route.params, 
      (toParams) => {
        if(route.name !== 'OrderDetail') return
        getBookingDetail(toParams.bookingId)
      },
      { immediate: true }
    )
    async function getBookingDetail(bookingId) {
      loading.show()
      const res = await getBookingDetailApi({
        bookingId,
      })
      loading.hide()

      if (res.code === 0) {
        const data = res.data
        state.consignee = data.consignee
        state.telephone = formatPhone(data.telephone)
        state.province = data.province
        state.city = data.city
        state.district = data.district
        state.street = data.street
        // state.gifts = data.receiveGift.map( giftItem => {
        //   giftItem.specString = `${giftItem.attributeValue1} ${giftItem.attributeValue2}`

        //   return giftItem
        // })
        // 根据快递单号分组
        data.receiveGift.forEach((giftItem) => {
          giftItem.specString = `${giftItem.attributeValue1} ${giftItem.attributeValue2}`;
          let item = state.gifts.find(
            (item) => item.logisticsNumber === giftItem.logisticsNumber
          );
          if (item) {
            item.items.push(giftItem);
          } else {
            item = {
              logisticsCode: giftItem.logisticsCode,
              logisticsNumber: giftItem.logisticsNumber,
              logisticsCompanyName: giftItem.logisticsCompanyName,
              items: [giftItem],
            };
            state.gifts.push(item);
          }
        });
      }
      else {
        defaultBack()
      }
    }

    function defaultBack() {
      if (history.length > 1) {
        router.go(-1)
      } 
      else {
        router.replace({ path: '/' })
      }
    }

    const showLogisticsModal = async (index) => {
      const gift = state.gifts[index]
      loading.show()
      const res = await getLogisticsInforApi({
        logisticsCompany: gift.logisticsCode,
        logisticsNum: gift.logisticsNumber,
      })
      loading.hide()

      if(res.data && res.data.length > 0) {
        state.logisticsCompanyName = gift.logisticsCompanyName
        state.logisticsNumber = gift.logisticsNumber
        state.logisticsInfo = res.data

        state.isShowLogisticsModal = true
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
      
    }
    const hideLogisticsModal = () => {
      state.isShowLogisticsModal = false
    }

    return {
      ...toRefs(state),
      showLogisticsModal,
      hideLogisticsModal,
    }
  },
} 